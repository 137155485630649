import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App'
import registerServiceWorker from './registerServiceWorker';
import './css/index.css';


if (localStorage.getItem("modoNocturno") === "activo"){
    require('./css/styleNocturno.css');
}
else{
    require('./css/style.css');
}

function principal(){
    ReactDOM.render(
        <StrictMode>
            <App />
        </StrictMode>,
        document.getElementById('root')
    );
}

setInterval(principal, 5000);
registerServiceWorker();