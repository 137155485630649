import React, { Component } from 'react'
// import MicrosoftIn from './MicrosoftIn';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import imagotipo from '../../media/images/empresa/imagotipo.png'
import inicio from '../../media/images/empresa/inicio.png'
import qs from '../../media/images/empresa/qs.png'
import mv_izq from '../../media/images/empresa/mv_izq.png'
import mv_cen from '../../media/images/empresa/mv_cen.png'
import mv_der from '../../media/images/empresa/mv_der.png'
import suc from '../../media/images/empresa/suc.jpg'
import m_rep from '../../media/images/empresa/m_repara.jpg'
import m_ren from '../../media/images/empresa/m_renueva.jpg'
import contacto from '../../media/images/empresa/contacto.jpg'
// import nom from '../../media/images/empresa/nom.jpg'
// import letra_c from '../../media/images/empresa/c_b.png'
// import { NavLink } from 'react-router-dom';
import { FaWhatsapp, FaFacebook, FaTiktok } from "react-icons/fa"
import "../../css/paginaWebE.css"


class PaginaWeb extends Component {

  constructor(props){
    super(props);
    this.state = {
      widthLogin: 0,
    }
  }
  
  componentDidMount(){
    let self = this;
    console.log("PW")
    
    if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      self.setState({ widthLogin : window.screen.width})
    }
    else{
      self.setState({ widthLogin : window.screen.width/3 })
    }
    localStorage.setItem('menu','principal')
     
  }

  render(){
    return(
      <div>
        <AppBar className="AppBar" style={{backgroundColor:"#FFF", color:"#D66545"}}>
            <Toolbar>
                <img
                    className='imagotipo'
                    src={imagotipo}
                    alt="imagen"
                />
                <div className="divLinks">
                    HOME | NOSOTROS | SUCURSALES | CONTACTO
                </div>
            </Toolbar>
        </AppBar>
        <div className="div_inicio">
            <img
                className='imagen_inicio'
                src={inicio}
                alt="imagen"
            />
            <div className="texto_fondo_inicio">
                <div  className="texto_inicio">TRANSFORMANDO SUEÑOS EN REALIDADES</div>
            </div>
        </div>
        <div className="div_qs">
            <div className="qs_izquierda">
                <img
                    className='qs_imagen'
                    src={qs}
                    alt="imagen"
                />
            </div>
            <div className="qs_derecha">
                <h1 className="qs_titulo">¿QUIÉNES SOMOS?</h1>
                <p className="qs_texto">
                    Somos C + Incomparable, la empresa número uno especializada en Administración,        
                    Asesoramiento y Gestión para Derechohabientes de los programas del Infonavit.
                    Un nuevo concepto empresarial que surge de la experiencia de un gran equipo de
                    profesionales especializados, en donde a través del análisis del perfil de nuestros
                    clientes, podemos determinar, con un alto grado de precisión y eficacia, cuál de los
                    productos que ofrecemos en nuestro portafolio es el más adecuado para satisfacer
                    sus necesidades presentes o futuras.
                </p>
            </div>
        </div>
        <div className="div_mv">
            <div className="mv_izquierda">
                <div>
                    <h1 className="mv_titulo_izq">MISIÓN</h1>
                    <p className="mv_texto_izq">
                        Generar el ecosistema empresarial que
                        fomente el espíritu emprendedor para
                        la creación de más y mejores empresas
                        sostenibles que generen empleos de
                        calidad.
                    </p>
                </div>
                <br />
                <div>
                    <img
                        className='mv_imagen_izquierda'
                        src={mv_izq}
                        alt="imagen"
                    />
                </div>
            </div>
            <div className="mv_centro">
                <div>
                    <p className="mv_c_ta">Cobertura</p>
                    <p className="mv_c_tb">NACIONAL</p>
                    <img
                        className='mv_imagen_centro'
                        src={mv_cen}
                        alt="imagen"
                    />
                </div>
            </div>
            <div className="mv_derecha">
                <div>
                    <img
                        className='mv_imagen_derecha'
                        src={mv_der}
                        alt="imagen"
                    />
                </div>
                <br />
                <div>
                    <h1 className="mv_titulo_der">VISIÓN</h1>
                    <p className="mv_texto_der">
                        Ser la compañía más avanzada en
                        nuestro país, en asesoría y
                        acompañamiento para nuestros
                        clientes, para la obtención y
                        administración de instrumentos y
                        soluciones financieras, a través de la
                        aplicación de la tecnología y el
                        respaldo de los profesionales más
                        experimentados en el sector, con el fin
                        de satisfacer todas las variables de las
                        necesidades de cada cliente, en el
                        momento preciso que lo necesita, con
                        la atención de calidad que merece y la
                        garantía de los mejores resultados.
                    </p>
                </div>
            </div>
        </div>
        <div className="">
            <img
                className='suc_imagen'
                src={suc}
                alt="imagen"
            />
        </div>
        {/* <div className="div_nom">
            <div className="nom_izquierda">
                <div className="nom_titulo_izq">LA NOM - 035</div>
                <p className="nom_texto_izq">
                    <b>IMPORTANCIA</b>
                    <br />
                    México se encuentra entre los primeros lugares con el mayor número de personas con
                    estrés laboral, debido a que muchas organizaciones no cuentan con las condiciones
                    adecuadas para el desarrollo de sus trabajadores.
                    <br />
                    <br />
                    Esta situación afecta ambas partes, ya que para los trabajadores implica problemas
                    emocionales y de salud que se traducen en poco involucramiento, y para las
                    organizaciones implica un mayor costo económico, pues genera baja productividad,
                    incremento de accidentes de trabajo, aumento de ausentismo, deterioro del ambiente
                    laboral y puede impactar en la reputación de la Organización.
                    <br />
                    <br />
                    <b>OBJETIVO</b>
                    <br />
                    Establecer los elementos para identiﬁcar, analizar y prevenir los factores de riesgo
                    psicosocial, así como para promover un entorno organizacional favorable en los centros de trabajo.
                    De acuerdo con el campo de aplicación, la NOM-035 rige en todo el territorio nacional
                    y aplica en todos los centros de trabajo.
                    <br />
                    <br />
                    Sin embargo, las disposiciones de esta norma aplican de acuerdo con el número de
                    trabajadores que laboran en el centro de trabajo.
                    <br />
                    Derivado de lo anterior, existen tres niveles:<br />
                    - Centros de trabajo donde laboran hasta 15 trabajadores.<br />
                    - Centros de trabajo donde laboran entre 16 y 50 trabajadores.<br />
                    - Centros de trabajo donde laboran más de 50 trabajadores.
                    <br />
                    <br />
                    Por lo que el centro de trabajo debe determinar en qué nivel se encuentra, para que
                    cumpla con las disposiciones que le corresponden de acuerdo con el número de
                    trabajadores que emplea.
                </p>
            </div>
            <div className="nom_derecha">
                <div className="nom_texto_der">
                    Porque la <b>estabilidad</b> y la <b>tranquilidad</b><br />
                    de los trabajadores<br />
                    es parte de <b>la paz</b> y el <b>progreso.</b><br />
                </div>
                <div>
                    <img
                        className='nom_imagen_derecha'
                        src={nom}
                        alt="imagen"
                    />
                </div>
                <br />
                
                    
                
            </div>
        </div> */}
        <div className="div_remodelar">
            <div className="remodelar_titulo">REMODELAR tu casa es escribir un nuevo capítulo lleno de ilusiones y esperanzas.</div>
                <div className="remodelar_subtitulo">
                    <b>MEJORAVIT REPARA</b> y <b>MEJORAVIT RENUEVA</b> son dos créditos no
                    hipotecarios otorgado por Infonavit, para reparar, ampliar o mejorar
                    tu vivienda sin afectar su estructura, los pagos mensuales de tu
                    crédito son fijos, incluso si llegaras a perder tu empleo con una tasa
                    de interés del 10% y 11% anual fija. Sin incremento anual en el pago de
                    tu crédito.
                </div>
                <br />
                <br />
            <div className="remodelar_centro">
                <div className="remodelar_rep">
                    <img
                        className=''
                        src={m_rep}
                        alt="imagen"
                    />
                    <br />
                    <br />
                    <h3><b>Monto máximo de crédito:</b></h3>
                    <br />
                    <h1><b>$37,844</b></h1>
                </div>
                <div className="remodelar_ren">
                    <img
                        className=''
                        src={m_ren}
                        alt="imagen"
                    />
                    <br />
                    <br />
                    <h3><b>Monto máximo de crédito:</b></h3>
                    <br />
                    <h1><b>$156,000</b></h1>
                </div>
            </div>
            <div className="remodelar_pie">
                <div className="remodelar_pie_izq">
                    Podrás elegir de 1 a 5 años de plazo para pagarlo.
                </div>
                <div className="remodelar_pie_der">
                Podrás elegir de 1 a 10 años de plazo para pagarlo.
                </div>

            </div>
        </div>
        <div className="contacto_div">
            <div className="contacto_izq">
                <img
                    className="contacto_imagen"
                    src={contacto}
                    alt="imagen"
                />
            </div>
            <div className="contacto_der">
                <div className="contacto_titulo">Contáctanos</div>
                <div>
                    <FaWhatsapp className="whatsapp" style={{fontSize: 50}}/><h1>55 0000 0000</h1>
                </div>
                <div>
                    <FaFacebook className="facebook" style={{fontSize: 50}}/><h1>C+ Incomparable</h1>
                </div>
                <div>
                    <FaTiktok className="tiktok" style={{fontSize: 50}}/><h1>C+ Incomparable</h1>
                </div>
            </div>
            
        </div>
        {/* <div className="div_mapa">
            <div className="map_izquierda">
                <img
                    className='cuadro1'
                    src={letra_c}
                    alt="imagen"
                />
            </div>
            <div className="map_derecha">
            </div>
        </div> */}
        
      </div>
    );
  }
}
export default PaginaWeb;