import React, { Component } from "react";
import PaginaWeb from '../dashboard/PaginaWeb';
// import Footer from "../dashboard/Footer";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentDidMount(){
    if(localStorage.getItem('modoNocturno') === null){
      localStorage.setItem('modoNocturno', 'inactivo');
    }
    
    if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
      localStorage.setItem('dispositivo', 'movil');
    }
    else{
      localStorage.setItem('dispositivo', 'computadora');
    }
  }


  resetState(){
  }
 
  render(){
    return (
      <div className="App">
        <PaginaWeb />  
        {/* <Footer
          loggedIn = {this.state.loggedIn}
        /> */}
      </div>
   );
  }
}
export default App;